import { graphql } from "gatsby"
import React, { useRef, useState, useEffect } from "react"
import "react-input-range/lib/css/index.css"
import useOutsideClick from "../../hooks/useOutsideClick"
import { handleLogin, isLoggedIn, getUser } from "../../services/auth"

import Layout from "../Page"
import FilterResult from "../../components/ContentBuilder/FilterResult"
import MainDesignFilter from "../Includes/MainDesignFilter"
import StayInTouch from "../Includes/StayInTouch"
import SidebarFilter from "../Includes/SidebarFilter"

import FeaturedProductsHome from "../Includes/FeaturedProductsHome"

import SearchBar from "../../components/SearchBar"
import ContactUs from "../../components/ContactUs"
import Elements from "../Elements/Elements"
import Cookies from "universal-cookie"
import config from "../../config/config"

const HomePage = ({ data: { silverStripeDataObject } }) => {
  const [showModal, setShowModal] = useState(true)
  useEffect(() => {
    const cookies = new Cookies()
    var modal_time = 1 * 60 * 60 * 1000
    var modalShowed = cookies.get("modalShow") ? true : false

    if (modalShowed === false) {
      cookies.set("modalShow", true, {
        path: "/explore/",
        expires: new Date(Date.now() + modal_time),
      })
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }, [])

  const [show, setShow] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setShow(false)
  })
  const host = config.host
  const {
    title,
    content,
  } = silverStripeDataObject.SilverStripeSiteTree
  const {
    bannerTitle,
    bannerSubTitle,
    bannerImageURL,
  } = silverStripeDataObject.Page
  var {
    bannerLink,coverImagePath,coverImageTabletPath,coverImageMobilePath
  } = silverStripeDataObject.HomePage
  if(!coverImageTabletPath){
      coverImageTabletPath = coverImagePath
  }
  if(!coverImageMobilePath){
      coverImageMobilePath = coverImagePath
  }
  const { metaTags } = silverStripeDataObject.Page;
  const pageData = {
    pageClass: "HomePage",
    bodyClass: "homepage",
    pageTitle: "",
    bannerLink: bannerLink,
    coverImage: coverImagePath,
    coverImageTablet: coverImageTabletPath,
    coverImageMobile: coverImageMobilePath,
    metaTags: metaTags,
  }
  return (
    <Layout pageData={pageData}>
      <div className="container">
        <MainDesignFilter intro={content} page={'homepage'} />
        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section main-wrap row mt-5 no-padding-mobile">
          <div className="col-12">
            <FeaturedProductsHome />
          </div>
        </div>
      </div>

      <StayInTouch />
    </Layout>
  )
}

export default HomePage
export const pageQuery = graphql`
  query($link: String!) {
    silverStripeDataObject(link: { eq: $link }) {
      SilverStripeSiteTree {
        title
        content
      }
      HomePage {
        bannerLink
        coverImagePath
        coverImageTabletPath
        coverImageMobilePath
      }
      Page {
        metaTags
        bannerTitle
        bannerSubTitle
        bannerImageURL
      }
    }
  }
`
